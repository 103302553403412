import React from "react"
import Layout from "../components/layout"

const BrowLamination = () => {
  return (
    <Layout>
      <div className="antialised">
        <div className="relative py-16 bg-white overflow-hidden">
          <div className="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
            <div className="relative h-full text-lg max-w-prose mx-auto">
              <svg
                className="absolute top-12 left-full transform translate-x-12"
                width="404"
                height="384"
                fill="none"
                viewBox="0 0 404 384"
              >
                <defs>
                  <pattern
                    id="74b3fd99-0a6f-4271-bef2-e80eeafdf357"
                    x="0"
                    y="0"
                    width="20"
                    height="20"
                    patternUnits="userSpaceOnUse"
                  >
                    <rect
                      x="0"
                      y="0"
                      width="4"
                      height="4"
                      className="text-gray-200"
                      fill="currentColor"
                    />
                  </pattern>
                </defs>
                <rect
                  width="404"
                  height="384"
                  fill="url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)"
                />
              </svg>
              <svg
                className="absolute top-1/2 right-full transform -translate-y-1/2 -translate-x-32"
                width="404"
                height="384"
                fill="none"
                viewBox="0 0 404 384"
              >
                <defs>
                  <pattern
                    id="f210dbf6-a58d-4871-961e-36d5016a0f49"
                    x="0"
                    y="0"
                    width="20"
                    height="20"
                    patternUnits="userSpaceOnUse"
                  >
                    <rect
                      x="0"
                      y="0"
                      width="4"
                      height="4"
                      className="text-gray-200"
                      fill="currentColor"
                    />
                  </pattern>
                </defs>
                <rect
                  width="404"
                  height="384"
                  fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)"
                />
              </svg>
            </div>
          </div>
          <div className="relative px-4 sm:px-6 lg:px-8">
            <div className="text-lg max-w-prose mx-auto mb-6">
              <p className="text-base text-center leading-6 text-indigo-600 font-semibold tracking-wide uppercase">
                Nyhet hos oss
              </p>
              <h1 className="mt-2 mb-8 text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
                Brow Lamination
              </h1>
              <p className="text-xl text-gray-500 leading-8">
                Brow Lamination / Brow Lift är en rakpermanent av dina egna
                hårstrån på ögonbrynen. Behandlingen gör stråna mjuka och
                följsamma och du kommer att kunna forma dina ögonbryn som du
                vill - brynen får ett lyft och ett tätare, fylligare utseende.
                Brynen ”fastnar” inte i den nya formen som de stylas till utan
                du lägger dem själv tillrätta så som du vill ha dem och kan
                fixera dem med en stylingprodukt. Behandlingen passar alla som
                har lite egna strån, som vill ha naturligt snygga och
                lättstylade bryn - perfekt för dig som har lite stökiga bryn!
              </p>
            </div>
            <div className="prose prose-lg text-gray-500 mx-auto">
              <p>
                Resultatet håller i upp till <strong>8 veckor </strong>beroende
                på hur snabbt dina strån växer, och du kan med fördel fräscha
                upp dem med klassisk färg och form efter ca 4 veckor. Du bör ej
                göra återbesök på Brow Lamination tidigare än var 8:e vecka.
              </p>
              <p>
                De första 24 timmarna efter utförd behandling får du inte tvätta
                ansiktet eller applicera produkter eller smink på brynen.
                Simhall, bastu och annan hög värme ska undvikas de flesta 48
                timmarna.
              </p>
              <h2>Så går behandlingen till</h2>
              <p>
                Brynen rengörs för att få bort smuts, krämer och smink. Därefter
                appliceras vätskor för att behandla brynen och stråna jobbas upp
                i önskad riktning. I slutet av behandlingen färgas och noppas
                brynen, de stylas och avslutningsvis appliceras en vårdande
                keratinbehandling. Behandlingen tar 45-60 minuter.
              </p>
              <h2>Priser</h2>
              <ul>
                <li>Brow Lamination 650:-</li>
                <li>Brow Lamination + Lashlift: 1200:-</li>
              </ul>

              <p>
                Klicka på Boka tid uppe till höger, eller ring oss på 0651 - 300
                555 för att boka din brow lift. Varmt välkommen!
              </p>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}
export default BrowLamination
